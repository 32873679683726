<template>
    <div class="protection_works">
        <taskCommon title="考古发掘"
                    :currentTree="currentTree"
                    :list="list"
                    :snSearch="snSearch"
                    :listConfig="listConfig"
                    :defaultProps="defaultProps"
                    widthValue="280px"
                    @handleChange="handleChange"
        >
            <slot>
                <Mymap :showTool="true" :showOprate="true" class="map" :showSwitch="false" @initmap="initMap"></Mymap>
                <Dialog
                    ref="dialog"
                    :dialogData.sync="dialogData"
                    :ycysMc="ycysMc"
                    @closeDisesaseDialog="closeDisesaseDialog"
                    @saveSuccess="saveSuccess"
                >
                </Dialog>
            </slot>
        </taskCommon>
    </div>
</template>
<script>
import taskCommon from '../task-common';
import mixins from '../mixins';
import Dialog from './archaeology_dig_dialog';
import { mapActions } from 'vuex';
export default {
    name: 'archaeology_dig',
    components: {
        taskCommon,
        Dialog
    },
    mixins: [mixins],
    data() {
        return {
            currentId: 1403,
            list: [],
            listConfig: {
                name: 'JLMC',
                value: 'ID'
            },
            basicMap: null,
            ycysMc: '',
            selectedId: '',
            selectedIndex: 0,
            areaLayers: null,
            defaultProps: {
                children: 'child',
                label: 'JLMC'
            }
        };
    },
    mounted() {
        this.dialogData.title = '考古发掘监测配置';
    },
    methods: {
        ...mapActions([
            'getArchaeologyDigList',
        ]),
        handleClose() {

        },
        // 获得考古发掘项目列表
        async getBhgcList() {
            let res = await this.getArchaeologyDigList({ ItemCode: this.currentId });
            this.list = res;
            this.initZone();
        },
        initZone() { // 渲染保护范围
            let _this = this;
            this.areaLayers.clearLayers();
            this.list.forEach((zone, index) => {
                if (!zone.XMWZSL) return false;
                let geoJson = JSON.parse(zone.XMWZSL);
                // eslint-disable-next-line
                L.geoJSON(geoJson, {
                    onEachFeature: function(feature, layer) {
                        layer.selfId = zone.ID;
                        layer.selfType = 'polygon';
                        layer.QYMC = zone.JLMC;
                        _this.setFeatureEvents(layer, zone, index);
                        _this.areaLayers.addLayer(layer);
                        layer.bindPopup(zone.JLMC, {
                            closeButton: false,
                            closeOnClick: true
                        });
                    },
                    style() {
                        return {
                            color: '#1862ad',
                            fillColor: '#1862ad',
                            weight: 2
                        };
                    }
                });
            });
        },
        setFeatureEvents(feature, zone, index) { // 巡查区的事件
            let _this = this;
            feature.on('click', function(e) {
                // eslint-disable-next-line
                L.DomEvent.stopPropagation(e);
                _this.changeList([feature.selfId, feature.QYMC, index, 0, zone]);
            });
            feature.on('mouseover', function() {
                this.openPopup();
            });
            feature.on('mouseout', function() {
                this.closePopup();
            });
        },
        async changeList(val) { // 点击遗产要素列表，直接显示弹出框
            this.currentTree = val[0];
            this.ycysMc = val[1];
            this.selectedIndex = val[2];
            this.getJcDetail(val);
        },
        // 从列表视图点击 获得工程监测配置详情
        getJcDetail(item) {
            this.selectedId = item[0];
            this.dialogData.title = `${this.ycysMc}-监测任务配置`;
            this.dialogData.dialog = true;
            this.$refs.dialog.getDetail(item);
        },
        // 关闭弹框时 清除选中
        closeDisesaseDialog() {
            this.selectedId = '';
        },
        async initMap(map) {
            this.basicMap = map;
            // eslint-disable-next-line
            let areaLayers = L.featureGroup().addTo(this.basicMap);
            this.areaLayers = areaLayers;
            await this.getBhgcList();
        },
        // 保存成功后 重新获得工程范围列表
        async saveSuccess() {
            await this.getBhgcList();
        }
    }
};
</script>
<style lang="scss" scoped>
</style>